import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallbackComponent from "./pages/Components/ErrorBoundaryFallbackComponent/ErrorFallbackComponent";
const MyPage = lazy(() =>
  import("./pages/MyPage")
);
const DashboardPage = lazy(() =>
  import("./pages/DashboardPage")
);
const CustomersPage = lazy(() =>
  import("./pages/CustomersPage")
);
const VendorsPage = lazy(() =>
  import("./pages/VendorsPage")
);
const VendorsCategoryPage = lazy(() =>
  import("./pages/VendorsCategoryPage")
);
const VendorsSubscriptionPlansPage = lazy(() =>
  import("./pages/VendorsSubscriptionPlansPage")
);
const PaymentMethodMainPage = lazy(() =>
  import("./pages/PaymentMethodMainPage")
);
const AdminBlogs = lazy(() =>
  import("./pages/adminBlog/AdminBlogs")
);
const AdminSettings = lazy(() =>
  import("./pages/AdminSettings")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const VendorProfile = lazy(() =>
  import("./pages/userPages/wedding/VendorProfile")
);
const VendorPurchaseSubscription= lazy(() =>
  import("././modules/Auth/userAuthPages/VendorPurchaseSubscription")
);

const VendorForumSingle = lazy(() =>
  import("./pages/userPages/VendorForumSingle")
); 

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const errorHandler = (error, errorInfo) => {
    //console.log({error, errorInfo});
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallbackComponent} onError={errorHandler}>
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {/* Redirect from root URL to /dashboard. */}
        <Redirect exact from="/" to="/dashboard" />
    
        <ContentRoute path="/dashboard" component={DashboardPage} exact />
        <ContentRoute path="/customers" component={CustomersPage} exact />
        <ContentRoute path="/vendors" component={VendorsPage} exact />
        <ContentRoute path="/vendorcategory" component={VendorsCategoryPage} exact />
        <ContentRoute path="/subscriptionplans" component={VendorsSubscriptionPlansPage} exact />
        <ContentRoute path="/paymentmethod" component={PaymentMethodMainPage} exact />
        <ContentRoute path="/vendor-forum/${forumId}" component={VendorForumSingle} exact/>
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/blog" component={AdminBlogs} exact />
        <Route path="/settings" component={AdminSettings} exact />
        <Route path="/user-profile" component={UserProfilepage} />
        <ContentRoute path="/vendor-profile/:vendorId" component={VendorProfile} exact />
        <ContentRoute path="/vendor-purchase-subscription/:vendorId" component={VendorPurchaseSubscription} exact />
    
        {/* Fallback Redirect - If none of the above routes match, redirect to /dashboard */}
        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
    </ErrorBoundary>
  );
}
