  /* eslint-disable jsx-a11y/alt-text */
  import React, { useEffect, useState } from "react";
  import { useParams, useHistory } from "react-router-dom";
  import { useSelector } from "react-redux";  
  import { getVendorDetailsById , postVendorForumCommentsCall, postaddForumLikeCall , postremoveForumLikeCall } from '../../services/user-services/vendorForumService';
  import { Card, Table, Modal, Button, Form } from 'react-bootstrap';
  import TagsInput from "react-tagsinput";
  import 'react-tagsinput/react-tagsinput.css'
  import { useFormik } from "formik";
  import * as Yup from "yup";
  import * as Common from "../../General/common";
  import { AiFillLike } from "react-icons/ai";
  import { AiOutlineLike } from "react-icons/ai";
  import { AiFillMessage } from "react-icons/ai";


  function VendorForumSingle() {
    const history = useHistory();

    const { forumId } = useParams();
    const [vendorDetailsById, setVendorDetailsById] = useState([]);
    const [isLiked, setIsLiked] = useState(false);
    
    const loggedInUser = useSelector((store) => store.UserLoggedIn.loggedInUser);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [postingVendorForumComment, setPostingVendorForumComment] = useState(false);


    let fd = new FormData();

      const initialValues = {
          comment: '',
          forum_id: forumId,
          user_id: loggedInUser.id
      };

      const Schema = Yup.object().shape({
          comment: Yup.string()
            .required("Comment is required"),
        });

    

    const getVendorForumId =  async (id) => {
      try{
        const { data } = await getVendorDetailsById(id);
      
        if(data.success){
          setVendorDetailsById(data.data);
          // Check if the logged-in user has liked the forum
          const userHasLiked = data.data.likes.some(like => like.user_id === loggedInUser.id);
          setIsLiked(userHasLiked);
        }else{
          setVendorDetailsById([]);
        }
      }catch(error){
       
        setVendorDetailsById([]);
      }
    }


    const formik = useFormik({
      initialValues,
      validationSchema: Schema,
      onSubmit: (values, { setStatus, setSubmitting }) => {
        
          if(values.comment !== ''){
          postVendorForumComment(values)
          }

        setSubmitting(false);
      },
  });


  const postVendorForumComment = async (values) => {
  setPostingVendorForumComment(true);
    try {
      const { data } = await postVendorForumCommentsCall(values);
      if (data.success) {
        // success scenario
        Common.showSuccessAlertAutoClose(
          data.message,
          "success",
          "Success!",
          3000
        );
        formik.resetForm();
        handleClose();
        getVendorForumId(forumId);
      } else {
        Common.showSuccessAlertAutoClose(
          data.message,
          "warning",
          "Failed!",
          3000
        );
      }
    } catch (err) {
      if(err?.response?.data?.message){
        Common.showSuccessAlertAutoClose(
          err?.response?.data?.message,
          "warning",
          "Failed!",
          3000
        );
      }else{
        Common.showSuccessAlertAutoClose(
          "Some error occured while processing your request!",
          "warning",
          "Failed!",
          3000
        );
      }
    } finally {
      setPostingVendorForumComment(false);
    }
  };

  const postaddForumLike = async (values) =>{
      try{
          
          const { data } = await postaddForumLikeCall(values);
          
            if(data.success) 
            {
              getVendorForumId(forumId);
       
            }
            else{
     
                
            }
          
      }catch(error){

          
      }
  }

  const postremoveForumLike = async (values) =>{
    try{
        
        const { data } = await postremoveForumLikeCall(values);
        
          if(data.success) 
          {
            getVendorForumId(forumId);
      
          }
          else{
        
              
          }
        
    }catch(error){
    
        
    }
  }

  useEffect(() => {
    fd.append("comment", formik.values.comment);
    fd.append("forum_Id", formik.values.forum_id);
    fd.append("user_id", formik.user_id)
  }, [formik.values.comment, formik.values.forum_id, formik.values.user_id]);

    useEffect(() => {
      getVendorForumId(forumId);
    }, [forumId]);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove the event listener when component unmounts
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);


    return (
      isMobile ?
      <>
      {vendorDetailsById && vendorDetailsById.title && (
          <div  key={vendorDetailsById.id}>
              <div style={{ backgroundColor: '#A3A57A' , borderRadius: '5px', padding :'10px', marginBottom:'10px'}}>
              <div className='row'>
                  <div className='col-6'>
                      <h5 style={{ color: '#fff', fontSize: '14px', paddingLeft: '10px' }}>{vendorDetailsById.title}</h5>
                  </div>
                  <div className='col-6 text-end'>
                      <h5 style={{ color: '#fff', fontSize: '14px',paddingRight: '10px', float:'right' , textAlign:'end'}}> POSTED: {new Date(vendorDetailsById.created_at).toLocaleDateString()}</h5>
                  </div>
              </div>

            {vendorDetailsById.image && (
                <div
                  style={{
                    height: '300px', // Set the maximum height
                    overflow: 'hidden', // Hide overflow if necessary
                  }}
                >
                  <img
                    src={vendorDetailsById.image}
                    alt={vendorDetailsById.title}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Maintain aspect ratio
                  />
                </div>
              )} 
              <div className='col-12' style={{ backgroundColor: '#FFF', borderRadius: '5px' }}>
                  <p style={{ color: '#000', fontSize: '14px', padding:'5px' }}>{vendorDetailsById.description}</p>
              </div>
              <div className='row'>
              <div className="col-6">
              <div onClick={() => {
                  const isLiked = vendorDetailsById.likes.some(like => like.user_id === loggedInUser.id);
                  if (isLiked) {
                    const likeToRemoveId = vendorDetailsById.likes.find(like => like.user_id === loggedInUser.id).id;
                    postremoveForumLike(likeToRemoveId);
                  } else {  
                    postaddForumLike({
                      'forums_id': vendorDetailsById.id,
                      'user_id': loggedInUser.id
                    });
                  }
                }}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                { isLiked ? 
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px', marginRight: '5px' }}>
                    <AiFillLike color="#F4D3C5" size="1.5em" />
                  </div> : 
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px', marginRight: '5px' }}>
                    <AiOutlineLike  color="#F4D3C5" size="1.5em" />
                  </div>
                }
                <span style={{ color: '#fff'}}>{vendorDetailsById.likes.length}</span>
              </div>
            </div>

            <div className='col-6'>
                <div style={{ display: 'inline-flex', alignItems: 'center', backgroundColor: '#A3A57A', color: '#000000', borderRadius: '5px', marginBottom: '8px' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px' }}>
                  <AiFillMessage color="#F4D3C5" size="1.5em" />
                  </div>
                  <h6 style={{ fontSize: '12px', color: '#FFFF', marginLeft: '5px' }}>{vendorDetailsById.comments.length} Comments</h6>
                </div>
              </div>
              </div>
              
          </div>
          
        
        
          <div className='row' style={{ marginBottom:'20px', borderBottom: '1px solid #E2E2E2'}}>
          <div
                  className='col-12'         
              >
                <div role={'button'}
                  onClick={handleShow}
                  style={{ backgroundColor: '#333', color: '#FFFFFF', width: '150px', textAlign: 'center', borderRadius: '5px' , float:'right', marginBottom:'8px', padding:'8px'}}>
                <h6 style={{ fontSize:'12px' , color:'#fff'}}>ADD RESPONSE</h6>
                </div>
                
           </div>

          <div className="col-6">
            <h5 style={{ alignSelf: 'center' }}>RESPONSES</h5>
          </div>
          </div>


              {vendorDetailsById.comments.map((comment) => (
                  <React.Fragment key={comment.id}>
                <div className='row'>
                <img
                    src={
                      comment.user.profile_image === null ||
                      comment.user.profile_image === undefined ||
                      comment.user.profile_image === ""
                        ? "/media/pictures/default-avatar.svg"
                        : comment.user.profile_image
                    }
                    className="rounded-circle object-fit-cover"
                    width={"40px"}
                    height={"40px"}
                  />
                  <div className="column" style={{ marginLeft: '10px' }}>
                    <div className="row">
                      <div className="col">
                        <h6 style={{ fontSize: '12px' }}>{comment.user.name === null ? "GUEST" : comment.user.name}</h6>
                      </div>

                    </div>
                    <h6 style={{ fontSize: '12px' }}>{comment.vendor.company_name === null ? "" : comment.vendor.company_name}</h6>
                  </div>

                </div>                  
                  <div >
                    <p style={{ fontSize:'14px' , marginBottom:'20px' ,marginTop:'5px'}}>{comment.comment}</p>              
                </div>
                <div className='row' style={{ marginBottom:'10px', borderBottom: '1px solid #E2E2E2'}}>
              <div className="col-12 text-end">
              <p style={{fontSize: '10px', float:'right'}}>POSTED: {new Date(comment.created_at).toLocaleDateString()}</p>
              </div>
              </div>
                  </React.Fragment>
              ))}
          </div>
      )}

      {/* CREATE NEW POST MODAL */}
      <Modal
          show={show}
          onHide={() => {
              formik.resetForm();
              handleClose();
          }}
          backdrop={true} // Enable closing on click outside
          keyboard={true} // Enable closing with the escape key
          centered
          size="lg"
      >
          <Modal.Header closeButton className='border-0'>
              <Modal.Title className='add-new-tranding-topic-title'>Create new comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='row'>
                  <div className="col-md-12">
                      <Form.Group className="mb-8" controlId="formbasictitle">
                          <Form.Label className='modal-form-label'>Comment</Form.Label>
                          <Form.Control
                              type="text"
                              name="comment"
                              {...formik.getFieldProps("comment")}
                              className="input-shadow"
                          />
                          {formik.touched.comment && formik.errors.comment ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.comment}</div>
                              </div>
                          ) : null}
                      </Form.Group>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer className='border-0 py-2'>
              <Button
                  variant="dark"
                  className={`${(formik.isSubmitting || postingVendorForumComment) ? 'pr-9' : ''} upload-btn`}
                  disabled={formik.isSubmitting || postingVendorForumComment}
                  onClick={() => {
                      formik.handleSubmit();
                  }}
              >
                  Post
                  {(formik.isSubmitting || postingVendorForumComment) && <span className="ml-3 spinner spinner-white"></span>}
              </Button>
          </Modal.Footer>
      </Modal>
  </> : <>
      {vendorDetailsById && vendorDetailsById.title && (
          <div  key={vendorDetailsById.id}>
              <div style={{ backgroundColor: '#A3A57A' , borderRadius: '5px', padding :'10px', marginBottom:'10px'}}>
              <div className='row'>
                  <div className='col-6'>
                      <h5 style={{ color: '#fff', fontSize: '14px', paddingLeft: '10px' }}>{vendorDetailsById.title}</h5>
                  </div>
                  <div className='col-6 text-end'>
                      <h5 style={{ color: '#fff', fontSize: '14px',paddingRight: '10px', float:'right'}}> POSTED: {new Date(vendorDetailsById.created_at).toLocaleDateString()}</h5>
                  </div>
              </div>

            {vendorDetailsById.image && (
                <div
                  style={{
                    height: '300px', // Set the maximum height
                    overflow: 'hidden', // Hide overflow if necessary
                  }}
                >
                  <img
                    src={vendorDetailsById.image}
                    alt={vendorDetailsById.title}
                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Maintain aspect ratio
                  />
                </div>
              )} 
              <div className='col-12' style={{ backgroundColor: '#FFF', borderRadius: '5px' }}>
                  <p style={{ color: '#000', fontSize: '14px', padding:'5px' }}>{vendorDetailsById.description}</p>
              </div>
              <div className='row'>
              <div className="col-6">
              <div onClick={() => {
                  const isLiked = vendorDetailsById.likes.some(like => like.user_id === loggedInUser.id);
                  if (isLiked) {
                    const likeToRemoveId = vendorDetailsById.likes.find(like => like.user_id === loggedInUser.id).id;
                    postremoveForumLike(likeToRemoveId);
                  } else {  
                    postaddForumLike({
                      'forums_id': vendorDetailsById.id,
                      'user_id': loggedInUser.id
                    });
                  }
                }}
                style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                { isLiked ? 
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px', marginRight: '5px' }}>
                    <AiFillLike color="#F4D3C5" size="1.5em" />
                  </div> : 
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px', marginRight: '5px' }}>
                    <AiOutlineLike  color="#F4D3C5" size="1.5em" />
                  </div>
                }
                <span style={{ color: '#fff'}}>{vendorDetailsById.likes.length}</span>
              </div>
            </div>

            <div className='col-6'>
                <div style={{ display: 'inline-flex', alignItems: 'center', backgroundColor: '#A3A57A', color: '#000000', width: '200px', borderRadius: '5px', float: 'right', marginBottom: '8px' }}>
                  <div style={{ borderRadius: '50%', backgroundColor: '#FFFFFF', padding: '5px', width: '30px' }}>
                  <AiFillMessage color="#F4D3C5" size="1.5em" />
                  </div>
                  <h6 style={{ fontSize: '12px', color: '#FFFF', marginLeft: '5px' }}>{vendorDetailsById.comments.length} Comments</h6>
                </div>
              </div>
              </div>
              
          </div>
          
        
        
          <div className='row' style={{ marginBottom:'20px', borderBottom: '1px solid #E2E2E2'}}>
          <div
                  className='col-12'         
              >
                <div role={'button'}
                  onClick={handleShow}
                  style={{ backgroundColor: '#333', color: '#FFFFFF', width: '150px', textAlign: 'center', borderRadius: '5px' , float:'right', marginBottom:'8px', padding:'8px'}}>
                <h6 style={{ fontSize:'12px' , color:'#fff'}}>ADD RESPONSE</h6>
                </div>
                
           </div>

          <div className="col-6">
            <h5 style={{ alignSelf: 'center' }}>RESPONSES</h5>
          </div>
          </div>


              {vendorDetailsById.comments.map((comment) => (
                  <React.Fragment key={comment.id}>
                <div className='row'>
                <img
                    src={
                      comment.user.profile_image === null ||
                      comment.user.profile_image === undefined ||
                      comment.user.profile_image === ""
                        ? "/media/pictures/default-avatar.svg"
                        : comment.user.profile_image
                    }
                    className="rounded-circle object-fit-cover"
                    width={"40px"}
                    height={"40px"}
                  />
                  <div className="column" style={{ marginLeft: '10px' }}>
                    <div className="row">
                      <div className="col">
                        <h6 style={{ fontSize: '12px' }}>{comment.user.name === null ? "GUEST" : comment.user.name}</h6>
                      </div>

                    </div>
                    <h6 style={{ fontSize: '12px' }}>{comment.vendor.company_name === null ? "" : comment.vendor.company_name}</h6>
                  </div>

                </div>                  
                  <div >
                    <p style={{ fontSize:'14px' , marginBottom:'20px' ,marginTop:'5px'}}>{comment.comment}</p>              
                </div>
                <div className='row' style={{ marginBottom:'10px', borderBottom: '1px solid #E2E2E2'}}>
              <div className="col-12 text-end">
              <p style={{fontSize: '10px', float:'right'}}>POSTED: {new Date(comment.created_at).toLocaleDateString()}</p>
              </div>
              </div>
                  </React.Fragment>
              ))}
          </div>
      )}

      {/* CREATE NEW POST MODAL */}
      <Modal
          show={show}
          onHide={() => {
              formik.resetForm();
              handleClose();
          }}
          backdrop={true} // Enable closing on click outside
          keyboard={true} // Enable closing with the escape key
          centered
          size="lg"
      >
          <Modal.Header closeButton className='border-0'>
              <Modal.Title className='add-new-tranding-topic-title'>Create new comment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div className='row'>
                  <div className="col-md-12">
                      <Form.Group className="mb-8" controlId="formbasictitle">
                          <Form.Label className='modal-form-label'>Comment</Form.Label>
                          <Form.Control
                              type="text"
                              name="comment"
                              {...formik.getFieldProps("comment")}
                              className="input-shadow"
                          />
                          {formik.touched.comment && formik.errors.comment ? (
                              <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">{formik.errors.comment}</div>
                              </div>
                          ) : null}
                      </Form.Group>
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer className='border-0 py-2'>
              <Button
                  variant="dark"
                  className={`${(formik.isSubmitting || postingVendorForumComment) ? 'pr-9' : ''} upload-btn`}
                  disabled={formik.isSubmitting || postingVendorForumComment}
                  onClick={() => {
                      formik.handleSubmit();
                  }}
              >
                  Post
                  {(formik.isSubmitting || postingVendorForumComment) && <span className="ml-3 spinner spinner-white"></span>}
              </Button>
          </Modal.Footer>
      </Modal>
    </>
    )
  }
  export default VendorForumSingle;