//export const ApiBaseUrl = "https://backwedding.reporteq.solutions/api/"; // API URL

//export const ApiBaseUrl = "http://44.195.90.156/api/"; // API URL

// export const ApiBaseUrl = "https://stagingapi.weddingmatch.com/api/";

export const ApiBaseUrl = "https://api.weddingmatch.com/api/";

export const apiUrls = {
  adminDashboard: {
    getDashboardStats: "admin/get-dashboard-stats"
  },
  contact: {
    contactUs: "submit-form"
  },
  adminSettings: {
    saveSettings: "save-settings",
    getSettings: "get-settings"
  },
  customers: {
      getCustomers: "admin/customer",
      insertCustomer: "admin/customer",
      updateCustomer: "admin/customer",
      deleteCustomer: "admin/customer",
      getCustomerDetailsById: "admin/customer"
  },
  vendors: {
    getVendors: "admin/vendor",
    insertVendor: "admin/vendor",
    updateVendor: "admin/vendor",
    deleteVendor: "admin/vendor",
    cancelSubscriptionVendorId: "/admin/vendor/cancelSubscriptionVendorId",
    getVendorDetailsById: "admin/vendor"
  },
  vendorsCategories: {
    getVendorsCategories: "admin/vendorcategory",
    insertVendorsCategory: "admin/vendorcategory",
    updateVendorsCategory: "admin/vendorcategory",
    deleteVendorsCategory: "admin/vendorcategory",
    getVendorsCategoryDetailsById: "admin/vendorcategory"
  },
  vendorsSubscriptionPlans: {
    getVendorsSubscriptionPlans: "admin/subscriptionplan",
    insertVendorsSubscriptionPlan: "admin/subscriptionplan",
    updateVendorsSubscriptionPlan: "admin/subscriptionplan",
    deleteVendorsSubscriptionPlan: "admin/subscriptionplan",
    getVendorsSubscriptionPlanDetailsById: "admin/subscriptionplan",
    getSubscriptionPlanByVendorId: "subscriptionplan"
  },
  paymentMethodDetails: {
    getPaymentMethodDetails: "admin/paymentmethodaccount",
    insertPaymentMethodDetail: "admin/paymentmethodaccount",
    updatePaymentMethodDetail: "admin/paymentmethodaccount",
    deletePaymentMethodDetail: "admin/paymentmethodaccount",
    changeStatus: "admin/paymentmethodaccountstatus"
  },
  paymentMethods: {
    getPaymentMethods: "admin/paymentmethod",
    insertPaymentMethod: "admin/paymentmethod",
    updatePaymentMethod: "admin/paymentmethod",
    deletePaymentMethod: "admin/paymentmethod",
    changeStatus: "admin/paymentmethodstatus"
  },
  locations: {
    getCountries: "country",
    getCities: "city",
    getStates: "state"
  },
  adminBlogs: {
    getAdminBlogPosts: "admin/blog",
    insertAdminBlogPost: "admin/blog",
    updateAdminBlogPost: "admin/blog",
    deleteAdminBlogPost: "admin/blog",
    getAdminBlogPostDetailsById: "admin/blog",
    getBlogCategories: "admin/blogcategories",
    getPublicBlogCategories: "blogcategories",
},
  user: {
    dashboard: {
      getCustomerDashboardDetails: "customer/dashboard",
      getVendorViews: "vendor/get-views"
    },
    hashtag: {
      getCustomerHashtag: "customer/hashtag"
    },
    vendorSignupProcess: {
      signup: "register/vendor",
      getGlobalVendorEvents: "global/get-vendor-events",
      getGlobalVendorEventCategories: "global/get-vendor-event-categories",
      getGlobalCompanyTypes: "global/get-company-types",
      getGlobalEventFilters: "global/get-event-filters",
      getGlobalVendorCategoryServices: "global/get-category-services",
      setupProfile: "vendor/profilesetup"
    },
    coupleSignupProcess: {
      signup: "register/customer"
    },
    userSigninProcess: {
      signin: "login",
      forgotPassword: "password/forgot-password",
      resetPassword: "password/reset"

    },
    reviews: {
      postReview: "reviews",
      getReviewById: "reviews",
      getAllReviews: "reviews",
      sendReviewResponse: "vendor/save-review-response"
    },
    guests: {
      addGuests: "customer/guests",
      getGuestsById: "customer/guests",
      inviteGuests: "customer/invite-guests",
      getGuestsByEventId: "customer/get-invited-guests"
    },
    globalVendorCategories: {
      getGlobalVendorCategories: "global/get-vendor-categories",
      getGlobalVendorSubCategories: "global/get-sub-category-by-vendor-categories"
    },
    profile: {
      saveProfile: "customer/profilesetting",
      getProfileInfo: "customer/profileinfo",
      getVendorProfileInfo: "vendor/profileinfo",
      saveVendorProfile: "vendor/profileupdate",
      increaseView: "increase-vendor-view"
    },
    userBlog: {
      getAllBlogPosts: "inspo",
      getBlogDetail: "inspo",
      getInspoFeaturedBlogPosts: "inspofeaturedposts",
      getAllBlogPostsByCategory: "inspo",
      getInspoFeaturedBlogPostsByCategory: "inspofeaturedposts"
    },
    checklist: {
      getCheckListItems: "customer/checklist",
      addCheckListItem: "customer/checklist",
      editCheckListItem: "customer/checklist",
      deleteCheckListItem: "customer/checklist",
      saveTaskCompletedValues: "customer/changebulkstatus",
      changeChecklistItemStatus: "customer/changestatus"
    },
    message: {
      sendMessage: "sendmessage",
      getAllChats: "getallchats",
      getAllMessages: "getchatmessages",
      deleteMessage: "deletemessage"
    },
    userVendor: {
      getVendorDetailsById: "vendor",
      getFilteredVendors: "filtervendors"
    },
    savedVendors: {
      getSavedVendors: "customer/savedvendors",
      saveVendorToList: "customer/savedvendors",
      unsaveVendorFromList: "customer/unsavevendor"
    },
    payment: {
      pay: "setupintent",
      updateVendorSubscription: "updatesubscription",
      updateVendorSubscriptionCustom: "updatesubscriptionCustom",
      updateVendorDashSubscription: "updatesubdashboard",
      setupNewCardIntent: "vendor/new-card-setup-intent",
      addNewCard: "vendor/payment-methods",
      getCards: "vendor/payment-methods",
      deleteCard: "vendor/payment-methods",
      checkCouponValidation: "check-coupon"
    },
    website: {
      createSite: "customer/weddingsite/create",
      updateSite: "customer/weddingsite/updateTemplate",
      getSiteData: "customer/weddingsite",
      updateHomePage: "customer/update-home-page",
      getHomePageData: "customer",
      updateSchedulePage: "customer/update-schedule-page",
      getSchedulePageData: "customer",
      updateTravelPage: "customer/update-travel-page",
      getTravelPageData: "customer",
      updatePartyPage: "customer/update-party-page",
      getPartyPageData: "customer",
      updateTodoPage: "customer/update-todo-page",
      getTodoPageData: "customer",
      updateFaqPage: "customer/update-faqs-page",
      getFaqPageData: "customer",
      updateUsPage: "customer/update-us-page",
      getUsPageData: "customer",
      updateRsvpPage: "customer/update-rsvp-page",
      getRsvpPageData: "customer",
      getEventTypes: "global/get-wedding-event-types",
      getEvents: "customer/weddingevents",
      addEvent: "customer/weddingevents",
      updateEvent: "customer/weddingevents",
      changePageVisibility: "customer/change-page-visibility",
      changePageStatus: "customer/change-page-status",
      changeSlug: "customer/change-site-slug",
      setPassword: "customer/set-password",
      disablePassword: "customer/disable-password",
      checkPassword: "customer/check-password",
      getGuestDetails: "customer/get-guest-details",
      saveGuestResponse: "customer/save-guest-response",
      getEventGuests: "customer/get-invited-guests",
      getCustomerWebsiteByCustomerId: "customer/get-customer-website",
      getRegistryPageData: "customer",
      getWeddingDetails: "customer/weddingdetails"
    },
    vendorList: {
      getVendorListByCategory: "vendor-list-by-category"
    },
    honeymoonLocations: {
      getHoneymoonLocations: "global/get-honeymoon-locations"
    },
    sweepstakes: {
      createSweepstake: "sweepstakes/create"
    },
    registry: {
      getRegistryRetailers: "global/get-registry-retailers",
      searchCouple: "global/search-couple",
      getAllCustomerRegistries: "customer/registry",
      createCustomerRegistry: "customer/registry",
      deleteCustomerRegistry: "customer/registry",
      changeRegistryVisibility: "customer/registry/change-registry-visibility",
      changeRegistryRetailerView: "customer/registry/change-registry-retailer-visibility",
      getRegistryWebistePage: (slug) => `customer/${slug}/get-registry-page`
    },
    vendorForum: {
      postVendorForum: "vendor/forum/create",
      getVendorForum: "vendor/forum",
      getVendorForumById : "vendor/forum/show",
      postVendorForumComment:"vendor/forum/add-comment",
      addForumLike:"vendor/forum/addLike",
      removeForumLike:"vendor/forum/removeLike"
    },
    spotlightVendors: {
      getSpotlightVendors: "top-result-vendors",
      getTopVendors :"top-vendors"
    }
  }
};
